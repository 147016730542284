import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../style/global-style.sass";
import useWindowSize from '../hooks/useWindowSize';
import CarouselCard from './CarouselCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';


function SampleNextArrow(props) {
  const { width } = useWindowSize();
  const { className, style, onClick } = props;
  const nxtClass = props.orangeSlide ? 'carousel-arrow-orange' : 'carousel-arrow-teal'
  return (
    <div
      className={className}
      onClick={onClick}
      style={{zIndex:1, width: (width > 768 ? '4.5rem': ''), top: width > 768 ? "calc(100vh - 12rem)": "calc(50vh - 12rem)", margin: !props.orangeSlide ? (width > 768 ? "0% 13%": "") : ""}}
    ><FontAwesomeIcon icon={faAngleRight} className={nxtClass}/></div>
  );
}

function SamplePrevArrow(props) {
  const { width } = useWindowSize();
  const { className, style, onClick } = props;
  const prevClass = props.orangeSlide ? 'carousel-arrow-orange' : 'carousel-arrow-teal'
  return (
    <div
      className={className}
      onClick={onClick}
      style={{zIndex:1, width:'4.5rem', top: width > 768 ? "calc(100vh - 12rem)": "calc(50vh - 12rem)", margin: !props.orangeSlide ? (width > 768 ? "0% 13%": "") : ""}}
    ><FontAwesomeIcon icon={faAngleLeft} className={prevClass}/></div>
  );
}

const Carousel = ({ items, sliderOrange }) => {
  const { width } = useWindowSize();
  const [activeIndex, setActiveIndex] = useState(0);
  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };
  const settings = {
    customPaging: function(i) {
      return (
        <div style={{display: "flex", "flexDirection": "column", "justifyContent": "center", "alignItems": "center", "marginTop": "-6rem"}}>
          <div>
            <img src={items[i].img} style={{height:'4rem',width:'6rem', border: activeIndex === i ? `0.2rem solid ${items[i].titleColor}` : "0.2rem solid white", transition: "border-color 0.3s"}}/>
          </div>
          <div style={{width: "70%"}}>
            <p style={{color: activeIndex === i ? items[i].titleColor : "white", "marginTop": "5px",  transition: "color 0.3s", fontWeight: "500" , fontSize: 'clamp(0.7rem , 0.9vw , 1rem)'}}>{items[i].paginationTitle}</p>
          </div>
        </div>
      );
    
    },
    dots: width > 768 ? true : false,
    infinite: true,
    speed: 1000,
    slidesToShow:  1,
    slidesToScroll: 1,
    arrows: true,
    rows:1,
    nextArrow: <SampleNextArrow wideArrow={true} orangeSlide={sliderOrange}/>,
    prevArrow: <SamplePrevArrow wideArrow={true} orangeSlide={sliderOrange}/>,
    beforeChange: (current, next) => handleSlideChange(next)
  };

  return (
    <div className="custom-slider" >
        <Slider {...settings}>
          {items && items.map((item, index) => (
            <CarouselCard item={item} index={index} orangeSlide={sliderOrange}></CarouselCard>
          ))}
        </Slider>
    </div>
  );
};

export default Carousel;
