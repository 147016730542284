import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Features from "../components/Features";
import FullWidthImage from "../components/FullWidthImage";
import maskImg from "../img/mask.png";
import Carousel from '../components/Carousel';
import '../style/index.sass';
import "../style/testimonial.sass"
import OurClients from "../components/OurClients";
import Testimonials from "../components/Testimonials";
import useWindowSize from "../hooks/useWindowSize";
import SmallCarousel from "../components/SmallCarousel";
import HomePageAnimation from "../components/HomePageAnimation";
import UseGoToSection from "../components/UseGoToSection";
import ExpertisesCircles from "../components/ExpertisesCircles";


const flexRow = {
  'display':'flex',
  'flex-direction':'row'
}

const justifyBetween = {
  ...flexRow,
  'justify-content':'space-between'
}

const flexCol = {
  'display':'flex',
  'flex-direction':'column'
}

const alignCent = {
  ...flexCol,
  alignItems:'center'
}

const alignCenter = {
  display:'flex',
  alignItems:'center'
}

const justifyCent={
  ...flexRow,
  justifyContent:'center'
}

const justifyCenter = {
  display:'flex',
  justifyContent:'center'
}

const justifyStart = {
  display:'flex',
  justifyContent:'srart'
}

const alignStart = {
  display:'flex',
  alignItems:'srart'
}

const featureProductProducts = {
  width:'50%',
  display:'flex',
  alignItems:'start',
  justifyContent:'start',
  padding:'5rem 0rem 5rem 0rem',
}

const FeaturedProducts  = ()=> {
  const { width } = useWindowSize();
  return (
    <div className="featured-product-section">
      <div className="featured-product-header">
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
            <h1 className="featured-product-header-title">Featur</h1>
            <h3 className="featured-product-header-subtitle">
              <div style={{display:'flex',flexDirection:"column"}}>
                <ul className="featured-product-header-list">
                  <p className="featured-product-header-list-item">ed</p>&nbsp;Pr
                </ul>
              </div>
              oducts
            </h3>
          </div>
          <div><hr style={{ border: "1px solid #E65E27",margin:'0', backgroundColor: '#E65E27', width: "75px"}}/></div>
        </div>
      </div>
      <div className="featured-product-desktop-view">
        <div className="featured-product-products" style={{...justifyBetween,padding:'0rem 12% 0rem 12%',color:'black'}}>
          <div className="featured-product-image" style={{...featureProductProducts}}>
              <img src={"img/product1.png"} style={{width: '70%'}}></img>
          </div>
          <div className="featured-product-product" style={{...featureProductProducts,...flexCol,...justifyCenter}}>
            <p className="featured-product-product-name">Cintel</p>
            <p className="featured-product-product-description">Unlock the power of insights with our comprehensive pharma tool featuring a dynamic News Portal, Events Tracker, Congress Data, Analyst Commentary, and Historical Sales data—all in one place </p>
          </div>
        </div>
        <div style={{...justifyBetween,padding:'0rem 12% 0rem 12%',color:'black' }}>

          <div style={{...featureProductProducts,...flexCol,...justifyCenter, marginLeft: '1.3%'}}>
            <p className="featured-product-product-name">Intellisight</p>
            <p className="featured-product-product-description">Step into the future of data analytics with our AI-powered conversational interface. Revolutionizing data interaction, it simplifies complex analysis, empowering business users to swiftly make informed decisions</p>
          </div>
          <div style={{...featureProductProducts, justifyContent: "end"}}>
              <img src={"img/product2.png"} style={{width: '70%'}}></img>
          </div>
        </div>
        <div className="featured-product-products" style={{...justifyBetween,padding:'0rem 12% 0rem 12%',color:'black'}}>
          <div className="featured-product-image" style={{...featureProductProducts}}>
              <img src={"img/product3.png"} style={{width: '70%'}}></img>
          </div>
          <div className="featured-product-product" style={{...featureProductProducts,...flexCol,...justifyCenter}}>
            <p className="featured-product-product-name">Custom Application</p>
            <p className="featured-product-product-description">Unlock unparalleled flexibility with our custom application, designed to meet your specific business requirements and drive success in the pharmaceutical industry </p>
          </div>
        </div>
      </div>
      <div className="featured-product-mobile-view">
        <div className="featured-product-products">
          <div className="featured-product-image">
              <img src={"img/product1.png"} style={{width: '70%'}}></img>
          </div>
          <div className="featured-product-product">
            <p className="featured-product-product-name" style={{width: '100%'}}>Cintel</p>
            <p className="featured-product-product-description">Unlock the power of insights with our comprehensive pharma tool featuring a dynamic News Portal, Events Tracker, Congress Data, Analyst Commentary, and Historical Sales data—all in one place </p>
          </div>
        </div>
        <div className="featured-product-products">
          <div className="featured-product-image">
              <img src={"img/product2.png"} style={{width: '70%'}}></img>
          </div>
          <div className="featured-product-product">
            <p className="featured-product-product-name" style={{width: '100%'}}>Intellisight</p>
            <p className="featured-product-product-description">Step into the future of data analytics with our AI-powered conversational interface. Revolutionizing data interaction, it simplifies complex analysis, empowering business users to swiftly make informed decisions</p>
          </div>
        </div>
        <div className="featured-product-products">
          <div className="featured-product-image">
              <img src={"img/product3.png"} style={{width: '70%'}}></img>
          </div>
          <div className="featured-product-product">
            <p className="featured-product-product-name" style={{width: '100%'}}>Custom Application</p>
            <p className="featured-product-product-description">Unlock unparalleled flexibility with our custom application, designed to meet your specific business requirements and drive success in the pharmaceutical industry</p>
          </div>
        </div>
      </div>
      <div className="columns">
          <div className="column is-12 has-text-centered" >
            <Link className="btn" to="/products" style={{backgroundColor: '#E65E27',color:'white',margin:'2rem 0rem 2rem 0rem', boxShadow:'none'}}>
              {width > 768 ? "EXPLORE MORE" : "VIEW PRODUCT"}
            </Link>
          </div>
      </div>
    </div>
  )
}

const therapy = {
  backgroundImage: "url('img/istock.png')",
  height:'60rem',
  padding:'5rem 3rem 3rem 3rem',
  backgroundSize: "cover"
}

const therapyTitle = {
  color:'#EEEEEE',
  fontSize:'35px',
  fontWeight:'bold'
}


const TherapyArea = ({data}) => {
  const { width } = useWindowSize();
  return (
    <div className="therapy-section">
      <div className="therapy-section-title" style={{...flexCol,...justifyCenter,paddingTop:'3rem'}}>
        <h1 className='therapy-title'>
          Therapy Area Expertise
        </h1>
        <hr style={{ border: "1px solid #177E89",margin:'0',backgroundColor:'#177E89', width: "75px", alignSelf: "center"}}/>
        <p className='therapy-area-description'>
          Pharmsight’s therapeutic expertise spans large drug and disease markets, including oncology and neurology, as well as immunology and other conditions. Backed by 20 years of proven results, we provide services for the full development lifecycle for pharmaceutical companies and startup biotechs
        </p>
      </div>
    <div>
      <Carousel items={data} sliderOrange={false}></Carousel>
    </div>
    </div>
  );
};

const LatestBlog = ({data}) => {
  return (
    <div style={{marginTop:'6rem',marginBottom:'6rem'}}>
      <div style={{...flexCol,...justifyCenter,width:'100%',marginBottom:'4rem'}}>
        <h1 className="therapy-title" style={{color:'black'}}>
          Latest F
          <p style={{display:'flex','flex-direction':'column'}}>
            rom
            <hr style={{ border: "1px solid #E65E27",margin:'0',backgroundColor:'#E65E27'}}/>
          </p>&nbsp;the <p style={{'color':'#E65E27',marginLeft:'0.7rem'}}>Blog</p>
        </h1>
      </div>
      <div style={{padding:'0rem 1rem 0rem 2rem'}}>
        <SmallCarousel items={data}  sliderOrange={true}/>
      </div>
    </div>
  )
}

const Expertises = ({data}) => {
  const { width } = useWindowSize();
  const backgroundImageStyle = {
    backgroundImage: `url(${data.backgroundImage.topRight}), url(${data.backgroundImage.bottomLeft})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right, bottom left',
    backgroundSize: '15% 30%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  };
  return (
   <div style={width > 768 ? backgroundImageStyle : {display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
    <div className="expertises-section" style={{width: "80%"}}>
      <div className="expertises-circles-section">
        <ExpertisesCircles data={data.expertisesList}/>
      </div>
      <div className="expertises-title-section">
        <div className="expertises-section-title-mobile">
          <h2 className="expertises-section-title">
            {data.title.map((part, index) => (
              <span style={{ color: part.color, lineHeight: "1.1" }}>{part.fragment + " "}</span>
            ))}
          </h2>
        </div>
        <div className="expertises-title-description">{data.description}</div>
      </div>
    </div>
   </div>
  )
}

const OurSolutions = ({carousel}) => {
  const { width } = useWindowSize();
  return (
    <>
    <div style={{marginTop:'2rem',width:'100%',display:'flex',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
        <div className='our-solutions-title' style={{height:'20%',display:'flex',justifyContent:'center',alignItems:'center',padding:'2rem 2rem 1rem 2rem'}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                <h1 style={{marginRight:'0.4rem',fontWeight:'bold'}}>Our</h1>
                <h3 style={{'color': '#E65E27',display:'flex','flex-direction':'column',fontWeight:'bold'}}>
                  Solutions
                </h3>
              </div>
              <div>
                <hr style={{width: "75px", border: "1px solid #E65E27",margin:'0',backgroundColor:'#E65E27'}}/>
              </div>
          </div>
        </div>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center', padding: '0rem 2.5rem', fontSize: "clamp(1.1rem , 1.5vw, 1.7rem)", textAlign: "center" }}>
          We take a holistic, integrated, and cross-functional approach to deliver business solutions
        </div>
    </div>
    <div style={{padding:'3% 0% 0% 0%'}}>
      <Carousel items={carousel} sliderOrange={true}></Carousel>
    </div>
    </>
  )
}

const AnimatedVideo = () => {
  return (
    <video
    disablePictureInPicture
  src="img/LandingPageVideo.mp4" // Replace with the actual video URL or source path
  style={{
    objectFit: "cover", // Ensures the video covers the container without distortion
    gridArea: "1/1",
    height: "45rem", // Adjust to your desired height
    width: "100%",
  }} controls autoplay muted type="video/mp4">

  </video>
  // <video width="320" height="240" controls autoplay muted playsinline id="myVideo">
  // <source src="img/LandingPage.mp4" type="video/mp4"></source>
  // </video>
  )
}

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  subheading,
  points,
  intro,
  expertisesData,
  carousel,
  therapyData,
  latestBlogData,
  clients,
  testimonials
}) => {
  const heroImage = getImage(image) || image;

  const { width } = useWindowSize();


  return (
    <div>      
      <section className="section1 fit-page">
        <HomePageAnimation points= {points}/>
      </section> 
      <section className="section2 fit-page">
        <Features item={intro} bg={intro.background} gridItems={intro.blurbs} color={intro.color} bgColor={intro.buttoncolor} fontcolor={intro.fontcolor}/>
      </section>
      <section className="section3 fit-page">
        <Expertises data={expertisesData}/>
      </section>
      <section className="section4">
        <OurSolutions carousel={carousel}/>
      </section>
      <section className="section5">
        <FeaturedProducts/>
      </section>
      <section className="section6">
        <TherapyArea data={therapyData}/>
      </section>
      {/* <section>
        <LatestBlog data={latestBlogData}/>
      </section> */}
      <section class="secton is-medium section8 fit-page testimonials-section" style={{backgroundColor: "black"}}>
        <Testimonials testimonials={testimonials} />
      </section>
      {/* <section class="section is-medium section9">
        <OurClients clients={clients}/>
      </section> */}
      <UseGoToSection />
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  points: PropTypes.array,
  intro: PropTypes.shape({
    background: PropTypes.string,
    title1: PropTypes.string,
    title2: PropTypes.string,
    description: PropTypes.string,
    blurbs: PropTypes.array,
    button: PropTypes.string,
    color: PropTypes.string,
    buttoncolor: PropTypes.string,
    fontcolor: PropTypes.string,
  }),
  expertisesData: PropTypes.shape({
    title: PropTypes.array,
    description: PropTypes.string,
    expertisesData: PropTypes.string,
    backgroundImage: PropTypes.object,
    expertisesList: PropTypes.array
  }),
  carousel: PropTypes.array,
  therapyData: PropTypes.array,
  latestBlogData: PropTypes.array,
  clients: PropTypes.array,
  testimonials: PropTypes.array
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        points={frontmatter.points}
        description={frontmatter.description}
        intro={frontmatter.intro}
        expertisesData={frontmatter.expertisesData}
        carousel={frontmatter.carousel}
        therapyData={frontmatter.therapyData}
        latestBlogData={frontmatter.latestBlogData}
        clients={frontmatter.clients}
        testimonials={frontmatter.testimonials}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        points {
          top
          left
          textTop
          textLeft
          mobileTextTop
          mobileTextLeft
          mobileWidth
          width
          title
          description
        }
        intro {
          background
          title1
          title2
          description
          blurbs {
            logo
            percent
            title
            color
          }
          button
          color
          buttoncolor
          fontcolor
        }
        expertisesData {
          title {
            fragment
            color
          }
          description
          expertisesImage
          backgroundImage {
            topRight
            bottomLeft
          }
          expertisesList {
            title
            icon
            description
            backgroundColor
          }
        }
        carousel {
          title
          titleColor
          paginationTitle
          description
          img
          width 
          path
          icons {
            title
            icon {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            icon2 {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            index
            hoverText
            backgroundColor
            hoverBackgroundColor
          }
        }
        therapyData {
          title
          titleColor
          paginationTitle
          description
          img 
          path
          diseasesList {
            name
          }
        }
        latestBlogData {
          title
          description
          img 
        }
        clients {
          title
          img
        }
        testimonials {
          author
          quote
          profession
          caption
          startQuote
          endQuote
        }
      }
    }
  }
`;
